import {
  Select,
  Tooltip,
  Tag,
  Icon,
  AutoComplete,
  message,
} from 'antd';

import axios from 'axios';
import { backendUrl } from '@/utils/utils';

import moment from 'moment-timezone';
moment.tz.setDefault("Asia/Taipei");

import styles from './common.less';

const {Option} = Select;

export const distinctPush = (arr, ele)=>{
  const push = (arr, ele)=>{
    if(!arr.includes(ele)){
      arr.push(ele)
    }
  }
  if(Array.isArray(ele)){
    ele.forEach(v=>push(arr,v))
  }else{
    push(arr, ele)
  }
}

export const pull = (arr, eles)=>{
  return arr.filter(v=>!eles.includes(v));
}

export const time_options = [
  (<Option key="06:00" value="06:00">06:00</Option>),
  (<Option key="06:30" value="06:30">06:30</Option>),
  (<Option key="07:00" value="07:00">07:00</Option>),
  (<Option key="07:30" value="07:30">07:30</Option>),
  (<Option key="08:00" value="08:00">08:00</Option>),
  (<Option key="08:30" value="08:30">08:30</Option>),
  (<Option key="09:00" value="09:00">09:00</Option>),
  (<Option key="09:30" value="09:30">09:30</Option>),
  (<Option key="10:00" value="10:00">10:00</Option>),
  (<Option key="10:30" value="10:30">10:30</Option>),
  (<Option key="11:00" value="11:00">11:00</Option>),
  (<Option key="11:30" value="11:30">11:30</Option>),
  (<Option key="12:00" value="12:00">12:00</Option>),
  (<Option key="12:30" value="12:30">12:30</Option>),
  (<Option key="13:00" value="13:00">13:00</Option>),
  (<Option key="13:30" value="13:30">13:30</Option>),
  (<Option key="14:00" value="14:00">14:00</Option>),
  (<Option key="14:30" value="14:30">14:30</Option>),
  (<Option key="15:00" value="15:00">15:00</Option>),
  (<Option key="15:30" value="15:30">15:30</Option>),
  (<Option key="16:00" value="16:00">16:00</Option>),
  (<Option key="16:30" value="16:30">16:30</Option>),
  (<Option key="17:00" value="17:00">17:00</Option>),
  (<Option key="17:30" value="17:30">17:30</Option>),
  (<Option key="18:00" value="18:00">18:00</Option>),
  (<Option key="18:30" value="18:30">18:30</Option>),
  (<Option key="19:00" value="19:00">19:00</Option>),
  (<Option key="19:30" value="19:30">19:30</Option>),
  (<Option key="20:00" value="20:00">20:00</Option>),
  (<Option key="20:30" value="20:30">20:30</Option>),
  (<Option key="21:00" value="21:00">21:00</Option>),
  (<Option key="21:30" value="21:30">21:30</Option>),
  (<Option key="22:00" value="22:00">22:00</Option>),
  (<Option key="22:30" value="22:30">22:30</Option>),
  (<Option key="23:00" value="23:00">23:00</Option>),
  (<Option key="23:30" value="23:30">23:30</Option>),
];

export const gs = (xs, sm, md, lg, xl) => ({xs, sm, md, lg, xl})

export const gs_12_12_6 = gs(12, 12, 6, 6, 6);
export const gs_24_12_6 = gs(24, 12, 6, 6, 6);
export const gs_24_8_8 = gs(24, 8, 8, 8, 8);
export const gs_12_8_8 = gs(12, 8, 8, 8, 8);

export const cvtTime = (din, tin="0:0") =>{
  const d = new Date(din)
  const t = (tin).split(':')
  return new Date(d.getYear(), d.getMonth(), d.getDate(), t[0], t[1]);
}

export const genSubmitedTag = (panel)=> <Tooltip key={panel} placement="topLeft" title={panelsConfig[panel].name}>
  <Tag color={panelsConfig[panel].color} className={styles.submitedTag}>{panelsConfig[panel].short}</Tag>
  </Tooltip>;

export const genBuyItmesTag = (d)=> {
  const name = d.substr("buyItems_".length);
  return <Tooltip key={d} placement="topLeft" title={name}>
    <Tag color="grey" className={styles.submitedTag}>{name}</Tag>
  </Tooltip>;
}

export const stopPropagation = function(e){
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
}

export const phoneParse = (str='')=>{
  const regex = /([^0-9^\r^\n]+)([0-9-]{10,12})[ $]?/gm;
  let m;
  let names = [];
  while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) {
          regex.lastIndex++;
      }
      names.push(m);
  }
  // const phones = names.map((e,idx)=>(<a key={`phone_${idx}`} onClick={stopPropagation} href={`tel:${e[2]}`}>✆{e[1]} </a>));
  const remains = str.replace(regex, '').trim();
  const regex2 = /^.+$/gm;
  let names2 = [];
  while ((m = regex2.exec(remains)) !== null) {
      if (m.index === regex.lastIndex) {
        regex2.lastIndex++;
      }
      names2.push(m);
  }

  return [
    ...names.map((e,idx)=>(<a key={`phone_${idx}`} onClick={stopPropagation} href={`tel:${e[2]}`}>✆{e[1]} </a>)),
    ...names2.map((e,idx)=>(<span key={`name_${idx}`} onClick={stopPropagation}>✪{e} </span>))
  ];

  // if(str.replace(regex, '').trim()!==''){
  //   return str; //`@${str.replace(regex, '').trim()}@`;
  // }else{
  //   return names.map((e,idx)=>(<a key={`phone_${idx}`} onClick={stopPropagation} href={`tel:${e[2]}`}>✆{e[1]} </a>));
  // }
}

export const machineParse = (str='')=>{
  const regex = /[*xX×]([0-9]+)/gm;
  let m;
  let arr = [];
  while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
          regex.lastIndex++;
      }
      arr.push(parseInt(m[1]));
      // // The result can be accessed through the `m`-variable.
      // m.forEach((match, groupIndex) => {
      //     console.log(`Found match, group ${groupIndex}: ${match}`);
      // });
  }
  return arr.reduce((a,b)=>a+b, 0);
}

export const machineShellParse = (str='')=>{
  const regex = /([^ ^、^\,^\d]+)[*xX×]([0-9]+)/gm;
  let m;
  let arr = [];
  let ret = {}
  while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
          regex.lastIndex++;
      }
      if(!ret.hasOwnProperty(m[1])){
        ret[m[1]] = 0;
      }
      ret[m[1]] += parseInt(m[2]);
      // arr.push(parseInt(m[1]));
      // // The result can be accessed through the `m`-variable.
      // m.forEach((match, groupIndex) => {
      //     console.log(`Found match, group ${groupIndex}: ${match}`);
      // });
  }
  return ret;
  // return arr.reduce((a,b)=>a+b, 0);
}
export const phoneParse_strict = (str='')=>{
  const regex = /([^0-9]+)([0-9-]{10,12})[ $]?/gm;
  let m;
  let names = [];
  while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
          regex.lastIndex++;
      }
      names.push(m);
      // // The result can be accessed through the `m`-variable.
      // m.forEach((match, groupIndex) => {
      //     console.log(`Found match, group ${groupIndex}: ${match}`);
      // });
  }
  if(str.replace(regex, '').trim()!==''){
    return str; //`@${str.replace(regex, '').trim()}@`;
  }else{
    return names.map((e,idx)=>(<a key={idx} onClick={stopPropagation} href={`tel:${e[2]}`}>✆{e[1]} </a>));
  }
}

export const customerVisible = (title="客戶可見", key="k")=> <Tooltip key={key} placement="topLeft" title={title}>
      <Icon type="eye" theme="twoTone" twoToneColor="#eb2f96" />
  </Tooltip>;

export const genSubmitedPanel = (panels)=> sortBy(panels, p=>panelsConfig[p].rank).map(genSubmitedTag)
export const genOrderStatePanel = ({orderPrinter, orderPhotobooth, orderInvitation, basicId, cfgWebUpload, orderMontage, games, quizs, gameEnabled, cfgMatt, buy_items=[], orderWine, orderBoothPlace})=> [
  basicId ? genSubmitedTag('basicId') : undefined,
  orderPrinter ? genSubmitedTag('orderPrinter') : undefined,
  orderPhotobooth ? genSubmitedTag('orderPhotobooth') : undefined,
  orderInvitation ? genSubmitedTag('orderInvitation') : undefined,
  cfgWebUpload ? genSubmitedTag('cfgWebUpload') : undefined,
  orderMontage ? genSubmitedTag('orderMontage') : undefined,
  gameEnabled && games && games.length>0 ? genSubmitedTag('games') : undefined,
  cfgMatt ? genSubmitedTag('matt') : undefined,
  quizs > 0 ? genSubmitedTag('quizs') : undefined,
  orderWine ? genSubmitedTag('wine') : undefined,
  orderBoothPlace ? genSubmitedTag('boothplace') : undefined,
  ...buy_items.map(bi=>genBuyItmesTag(bi))
]

export const panelsConfig = {
  basic_info: {
    name: '基本資訊',
    short: '基',
    color: 'magenta',
    rank: 0,
  },
  activity: {
    name: '活動設定',
    short: '設',
    color: 'orange',
    rank: 1,
  },
  material: {
    name: '邊框素材',
    short: '框',
    color: 'green',
    rank: 2,
  },
  offtext: {
    name: '謝客詞',
    short: '謝',
    color: 'cyan',
    rank: 3,
  },
  activity_confirm: {
    name: '行前確認',
    short: '確',
    color: 'blue',
    rank: 4,
  },
  payment: {
    name: '線上禮金',
    short: '金',
    color: 'yellow',
    rank: 5,
  },
  game_setting: {
    name: '遊戲設定',
    short: '遊戲',
    color: 'yellow',
    rank: 5,
  },
  montage_setting: {
    name: '蒙太奇設定',
    short: '蒙設',
    color: 'yellow',
    rank: 5,
  },

  try_printer: {
    name: '體驗拍貼系統',
    short: '體',
    color: 'red',
    rank: -5,
  },
  orderPrinter: {
    name: '訂購拍貼機',
    short: '拍',
    color: 'magenta',
    rank: -4,
  },
  orderInvitation: {
    name: '訂購電子喜帖',
    short: '帖',
    color: 'blue',
    rank: -3,
  },
  basicId: {
    name: '已產生帳號',
    short: '官',
    color: 'green',
    rank: -2,
  },
  cfgWebUpload: {
    name: 'MINI版',
    short: 'MINI',
    color: 'blue',
    rank: -1,
  },
  orderMontage: {
    name: '訂購蒙太奇',
    short: '蒙太奇',
    color: 'cyan',
    rank: 0,
  },
  orderPhotobooth: {
    name: '訂購韓式拍貼',
    short: '韓',
    color: 'red',
    rank: 0,
  },
  matt: {
    name: 'AI去背',
    short: 'AI',
    color: 'gold',
    rank: 0,
  },
  games: {
    name: '使用遊戲',
    short: '抽獎',
    color: 'geekblue',
    rank: -1,
  },
  quizs: {
    name: '使用快問快答',
    short: '問答',
    color: 'purple',
    rank: -2,
  },
  wine: {
    name: '點點酒',
    short: '酒',
    color: 'purple',
    rank: -3,
  },
  boothplace: {
    name: '拍照亭',
    short: '亭',
    color: 'green',
    rank: -4,
  },
}

export const panelsList = Object.keys(panelsConfig).map(e=>({value: e, ...panelsConfig[e]}))
export const panelsOptions = panelsList.map(e=>((<Option key={e.value} value={e.value}>{e.name}</Option>)))

export const userEditableName = {
  'date': '活動日期',
  'title': '官方帳號名稱',
  'groom_name': '新郎姓名',
  'bride_name': '新娘姓名',
  'groom_ename': '新郎英文名',
  'bride_ename': '新娘英文名',
  'frames_material': '邊框設定',
  'start_time': '開始時間',
  'end_time': '結束時間',
  'contact_it': '故障排除',
  'contact_location': '婚企(場地)',
  'contact_lottery': '抽獎人',
  'contact_order': '訂購人',
  'contact_presenter': '主持人',
  'location_addr': '宴客地址',
  'location_area': '宴客地區',
  'location_readable': '宴客地點(飯店/廳別)',
  'card_num': '桌卡數量',
  'frames_word': '文字框',
  'frames_person': '人物框',
  'frames_korean': '韓式框',
  // 'frameOrder': '邊框順序',
  'defaultPrintNumber': '列印額度',

  'locationGoogleUrl': '宴客地圖網址(電子喜帖',

  'material_note': '邊框備註',
  'tail_fee_last5': '轉帳末5碼',
  'tail_fee_date': '轉帳日期',

  'customerCheckFrame': '確認邊框',
  'customerCheckLottery': '確認抽獎',
  'customerCheckPower': '確認電源',
  'customerCheckDistance': '確認距離',
  'customerCheckComputer': '確認電腦',
  'customerCheckMeal': '確認餐點',
  'customerCheckOverflow': '確認超時處理方式',
  'customerCheckOverflowSelect': '超時處理方式',
  'customerCheckCoupon': '確認提供加值碼',
  'customerCheckCouponSelect': '確認提供加值碼選項',
  "customerCheckPropaganda": '確認桌宣',
  "customerCheckPropagandaSelect": '確認桌宣選項',
  "customerCheckServiceProvider": '確認服務商',
  "customerCheckContentNotControl": '私人照片',
  "customerCheckMontage": '確認蒙太奇',
  "customerCheckSideShot": '確認側拍',
  "customerCheckSideShotSelect": '確認側拍選項',
  "customerCheckQuiz": '確認快問快答',

  'offText': '謝客詞',
  'offTextTime': '謝客詞時間',
  'try_printer': '體驗拍貼系統',
}

// export const orderItemOptions = {
//   'date': '手翻相冊',
//   'title': '號名稱',
//   'groom_name': '新郎姓名',
// }

export const userEditableList = Object.keys(userEditableName);

const transTitle = key => userEditableName[key] ? userEditableName[key] : key

export const userEditableDesc = Object.keys(userEditableName).map(name=>({
  key: name,
  title: transTitle(name),
  description: name,
  disabled: false,
}))

export const two_val_check = (key) => (data, ref)=>ref[key] === undefined ? data[key] : ref[key]

export const makeid = (length) => {
   var result           = '';
   var characters       = 'abcdefghijklmnopqrstuvwxyz';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

export const invitation_menu_config = [
  {
    key: 'free0-map-gallery-printer',
    title: '粉紅-導航-婚紗',
    fn: 'free0-map-gallery-printer'
  },
  {
    key: 'free0-map-gallery-survey-printer',
    title: '粉紅-導航-婚紗-問卷',
    fn: 'free0-map-gallery-survey-printer'
  },
  {
    key: 'free0-map-printer',
    title: '粉紅-導航',
    fn: 'free0-map-printer'
  },
  {
    key: 'free0-map-survey-printer',
    title: '粉紅-導航-問卷',
    fn: 'free0-map-survey-printer'
  },
  {
    key: 'free1-map-gallery-printer',
    title: '大約-導航-婚紗',
    fn: 'free1-map-gallery-printer'
  },
  {
    key: 'free1-map-gallery-survey-printer',
    title: '大約-導航-婚紗-問卷',
    fn: 'free1-map-gallery-survey-printer'
  },
  {
    key: 'free1-map-printer',
    title: '大約-導航',
    fn: 'free1-map-printer'
  },
  {
    key: 'free1-map-survey-printer',
    title: '大約-導航-問卷',
    fn: 'free1-map-survey-printer'
  },
  {
    key: 'free2-map-survey-printer',
    title: '中式-導航-問卷',
    fn: 'free2-map-survey-printer'
  },
  {
    key: 'free2-map-gallery-printer',
    title: '中式-導航-婚紗',
    fn: 'free2-map-gallery-printer'
  },
  {
    key: 'free2-map-gallery-survey-printer',
    title: '中式-導航-婚紗-問卷',
    fn: 'free2-map-gallery-survey-printer'
  },
  {
    key: 'free2-map-printer',
    title: '中式-導航',
    fn: 'free2-map-printer'
  },
  {
    key: 'free3-map-gallery-printer',
    title: '大栽-導航-婚紗',
    fn: 'free3-map-gallery-printer'
  },
  {
    key: 'free3-map-gallery-survey-printer',
    title: '大栽-導航-婚紗-問卷',
    fn: 'free3-map-gallery-survey-printer'
  },
  {
    key: 'free4-map-gallery-survey-printer',
    title: '直簡-導航-婚紗-問卷',
    fn: 'free4-map-gallery-survey-printer'
  },
  {
    key:'f0-map',
    title: '粉紅-導航',
    fn:'f0-map'
  },
  {
    key:'f0',
    title: '粉紅-問卷+導航',
    fn:'f0'
  },
  {
    key:'f0',
    title: '粉紅-問卷+導航',
    fn:'f0'
  },
  {
    key:'f0-gallery-printer',
    title: '粉紅-婚紗+問卷+導航',
    fn:'f0-gallery-printer'
  },
  /*
  {
    key:'a1',
    title: '喜帖-大理石',
    fn:'a1'
  },
  {
    key:'a2',
    title: '婚禮-中式',
    fn:'a2'
  },
  {
    key:'a3',
    title: '婚禮-高雅',
    fn:'a3'
  },
  {
    key:'vend_romancelife',
    title: '廠商-浪漫一生',
    fn:'vend_romancelife'
  },
  {
    key:'a4',
    title: '婚禮-大理石2',
    fn:'a4'
  },
  {
    key:'p1',
    title: '喜帖-大理石',
    fn:'p1'
  },*/
];

export const menu_config = [
    {
      key:'menu_gallery_invitation_quiz',
      title: '喜帖+婚禮+婚紗+快問',
      fn:'menu_gallery_invitation_quiz.jpg'
    },
    {
      key:'menu_gallery_invitation',
      title: '喜帖+婚紗',
      fn:'menu_gallery_invitation.jpg'
    },
    {
      key:'menu_gallery_quiz',
      title: '婚紗+快問',
      fn:'menu_gallery_quiz.jpg'
    },
    {
      key:'menu_gallery',
      title: '婚紗',
      fn:'menu_gallery.jpg'
    },
    {
      key:'menu_simple',
      title: '基本',
      fn:'menu_simple.jpg'
    },
    {
      key:'menu_lottery',
      title: '抽獎(有主題抽獎才選這個)',
      fn:'menu_lottery.jpg'
    },
    {
      key:'menu_activity',
      title: '[活動]抽獎+快訊(有主題抽獎才選這個)',
      fn:'menu_activity.jpg'
    },
    {
      key:'menu_activity_simple',
      title: '活動場',
      fn:'menu_activity_simple.jpg'
    },
    {
      key:'menu_lottery_gallery_invitation',
      title: '喜帖+抽獎+婚紗(有主題抽獎才選這個)',
      fn:'menu_lottery_gallery_invitation.jpg'
    },
    {
      key:'menu_lottery_gallery',
      title: '抽獎+婚紗(有主題抽獎才選這個)',
      fn:'menu_lottery_gallery.jpg'
    },
    {
      key:'menu_lottery_gallery_quiz',
      title: '抽獎+婚紗+快問(有主題抽獎才選這個)',
      fn:'menu_lottery_gallery_quiz.jpg'
    },
    {
      key:'menu_lottery_gallery_invitation_quiz',
      title: '喜帖+抽獎+婚紗+快問(有主題抽獎才選這個)',
      fn:'menu_lottery_gallery_invitation_quiz.jpg'
    },
  ];



export const default_menu_config = [
  {
    key:'',
    title: '無選單'
  },
  {
    key:'printer',
    title: '拍拍印',
  },
  {
    key:'invitation',
    title: '電子喜帖',
  },
];

export const buyItems = [
  { name: "buyItems_手翻相冊", display:"手翻相冊" },
  { name: "buyItems_手翻相冊(韓式)", display:"手翻相冊(韓式)" },
  { name: "buyItems_似顏繪", display:"似顏繪" },
  { name: "buyItems_紙相框", display:"紙相框" },
  { name: "buyItems_拍照亭", display:"拍照亭" },
  { name: "buyItems_時光桌曆", display:"時光桌曆" },
  { name: "buyItems_婚後蒙太奇", display:"婚後蒙太奇" },
  { name: "buyItems_美顏燈", display:"美顏燈" },
  { name: "buyItems_婚後30張", display:"婚後30張" },
  { name: "buyItems_星光燈+拍立得平板", display:"星光+平板" },

  { name: "buyItems_韓式木框(二連)", display:"韓式木框(二連)" },
  { name: "buyItems_美式木框(三連)", display:"美式木框(三連)" },
  { name: "buyItems_韓式水晶框", display:"韓式水晶框" },
  { name: "buyItems_韓式水晶框(二連)", display:"韓式水晶框(二連)" },
]
export const buyItemsOptions = buyItems.map(d=><Option key={d.name} value={d.name} label={d.display}>{d.display}</Option>);

export const gamesItems = [
  { name: "spin", display:"歡樂大轉盤" },
  { name: "ball", display:"魔幻彩球" },
  { name: "quiz", display:"快問快答" },
  { name: "ballon", display:"捧花爭奪戰" },
  { name: "lotto", display:"幸福樂透520" },
]
export const gamesItemsOptions = gamesItems.map(d=><Option key={d.name} value={d.name} label={d.display}>{d.display}</Option>);

export const planItems = [
  { name: "2024婚禮精選版",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2024_classic-240401.jpg",
    keywords:["精選版"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: false,
      orderNavigation: true,
      cfgWebUpload: false,
      gameEnabled: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*1台',
      "serviceBelong.source.user": 'official',
    }
  },
  { name: "2024韓式三連拍",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2024_korean-240401.jpg",
    keywords:["韓式三連拍"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: false,
      orderNavigation: true,
      cfgWebUpload: false,
      gameEnabled: true,
      orderPhotobooth: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*1台',
      "serviceBelong.source.user": 'official',
    }
  },
  { name: "2024派對網頁版",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2024_web-240401.jpg",
    keywords:["派對網頁版", "網頁版"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: false,
      orderNavigation: true,
      cfgWebUpload: true,
      gameEnabled: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*1台',
      "serviceBelong.source.user": 'official',
    }
  },
  { name: "2024美式拍照亭",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2024_photobooth-240401.jpg",
    keywords:["拍照亭"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: false,
      orderNavigation: true,
      cfgWebUpload: false,
      gameEnabled: true,
      maxGames: 2,
      orderPhotobooth: true,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*1台',
      "serviceBelong.source.user": 'official',
    }
  },
  { name: "2024iWedding",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2024_iwedding-240401.jpg",
    keywords:["iWedding", "iwedding"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: false,
      orderInvitation: false,
      orderNavigation: true,
      cfgWebUpload: false,
      gameEnabled: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      "serviceBelong.source.user": 'official',
    }
  },

  { name: "2024展覽-拍印A",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2024_fair-240401.jpg",
    keywords:["A方案", "展覽A"],
    config:{
      numFramesWord: 3, //文字框數量
      numFramesPerson: 2, //人物框數量
      orderPhotobooth: true,
      orderPrinter: true, //訂購拍貼機
      orderInvitation: true,  //訂購line喜帖
      orderNavigation: true, //訂購導航
      cfgWebUpload: false,  //使用line版
      gameEnabled: true,  //訂購遊戲
      maxGames: 2,  //可選遊戲數量
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'], //可選遊戲列表 spin大轉盤, ball魔幻彩球, quiz快問快答, ballon捧花, lotto樂透520
      note_machine: '不指定*1台', //機台數量
      "serviceBelong.source.user": 'linein_fair',
      "serviceBelong.saling.user": 'linein_fair',
    }
  },
  { name: "2024展覽-雙機B",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2024_fair-240401.jpg",
    keywords:["B方案", "雙機B"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: true,
      orderNavigation: true,
      cfgWebUpload: false,
      gameEnabled: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*2台',
      "serviceBelong.source.user": 'linein_fair',
      "serviceBelong.saling.user": 'linein_fair',
    }
  },
  { name: "2024展覽-韓式C",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2024_fair-240401.jpg",
    keywords:["C方案", "展覽A"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: false,
      orderNavigation: true,
      cfgWebUpload: false,
      gameEnabled: true,
      orderPhotobooth: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*2台',
      "serviceBelong.source.user": 'linein_fair',
      "serviceBelong.saling.user": 'linein_fair',
    }
  },

  //2023--------
  { name: "2023展覽-拍印A",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2023_fair-230828.jpg",
    keywords:["2023A方案", "2023展覽A"],
    config:{
      numFramesWord: 3, //文字框數量
      numFramesPerson: 2, //人物框數量
      orderPrinter: true, //訂購拍貼機
      orderInvitation: true,  //訂購line喜帖
      orderNavigation: true, //訂購導航
      cfgWebUpload: false,  //使用line版
      gameEnabled: true,  //訂購遊戲
      maxGames: 2,  //可選遊戲數量
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'], //可選遊戲列表 spin大轉盤, ball魔幻彩球, quiz快問快答, ballon捧花, lotto樂透520
      note_machine: '不指定*1台', //機台數量
      "serviceBelong.source.user": 'linein_fair',
      "serviceBelong.saling.user": 'linein_fair',
    }
  },
  { name: "2023展覽-拍印A",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2023_fair-230828.jpg",
    keywords:["2023A方案", "2023展覽A"],
    config:{
      numFramesWord: 3, //文字框數量
      numFramesPerson: 2, //人物框數量
      orderPrinter: true, //訂購拍貼機
      orderInvitation: true,  //訂購line喜帖
      orderNavigation: true, //訂購導航
      cfgWebUpload: false,  //使用line版
      gameEnabled: true,  //訂購遊戲
      maxGames: 2,  //可選遊戲數量
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'], //可選遊戲列表 spin大轉盤, ball魔幻彩球, quiz快問快答, ballon捧花, lotto樂透520
      note_machine: '不指定*1台', //機台數量
      "serviceBelong.source.user": 'linein_fair',
      "serviceBelong.saling.user": 'linein_fair',
    }
  },
  { name: "2023展覽-拍印A",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2023_fair-230828.jpg",
    keywords:["2023A方案", "2023展覽A"],
    config:{
      numFramesWord: 3, //文字框數量
      numFramesPerson: 2, //人物框數量
      orderPrinter: true, //訂購拍貼機
      orderInvitation: true,  //訂購line喜帖
      orderNavigation: true, //訂購導航
      cfgWebUpload: false,  //使用line版
      gameEnabled: true,  //訂購遊戲
      maxGames: 2,  //可選遊戲數量
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'], //可選遊戲列表 spin大轉盤, ball魔幻彩球, quiz快問快答, ballon捧花, lotto樂透520
      note_machine: '不指定*1台', //機台數量
      "serviceBelong.source.user": 'linein_fair',
      "serviceBelong.saling.user": 'linein_fair',
    }
  },
  { name: "2023展覽-雙機B",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2023_fair-230828.jpg",
    keywords:["2023B方案", "2023雙機B"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: true,
      orderNavigation: true,
      cfgWebUpload: false,
      gameEnabled: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*2台',
      "serviceBelong.source.user": 'linein_fair',
      "serviceBelong.saling.user": 'linein_fair',
    }
  },
  { name: "2023展覽-韓式C",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2023_fair-230828.jpg",
    keywords:["2023C方案", "2023展覽A"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: false,
      orderNavigation: true,
      cfgWebUpload: false,
      gameEnabled: true,
      orderPhotobooth: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*2台',
      "serviceBelong.source.user": 'linein_fair',
      "serviceBelong.saling.user": 'linein_fair',
    }
  },
  { name: "2023婚禮精選版",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2023-classic-230828.jpg",
    keywords:["2023精選版"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: false,
      orderNavigation: true,
      cfgWebUpload: false,
      gameEnabled: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*1台',
      "serviceBelong.source.user": 'official',
    }
  },
  { name: "2023韓式三連拍",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2023-korean-230828.jpg",
    keywords:["2023韓式三連拍"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: false,
      orderNavigation: true,
      cfgWebUpload: false,
      gameEnabled: true,
      orderPhotobooth: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*1台',
      "serviceBelong.source.user": 'official',
    }
  },
  { name: "2023派對網頁版",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2023-web-230828.jpg",
    keywords:["2023派對網頁版", "2023網頁版"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: false,
      orderNavigation: true,
      cfgWebUpload: true,
      gameEnabled: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*1台',
      "serviceBelong.source.user": 'official',
    }
  },
  { name: "2023星燦機",
    url:"https://storage.googleapis.com/storage.linein.cc/files/plan/2023_star-230828.jpg",
    keywords:["2023星燦機"],
    config:{
      numFramesWord: 3,
      numFramesPerson: 2,
      orderPrinter: true,
      orderInvitation: false,
      orderNavigation: true,
      cfgWebUpload: false,
      gameEnabled: true,
      maxGames: 2,
      enabledGames: ['spin', 'ball', 'quiz', 'ballon', 'lotto'],
      note_machine: '不指定*1台',
      "serviceBelong.source.user": 'official',
    }
  },
]
export const planItemsOptions = planItems.map(d=><AutoComplete.Option key={d.name} value={d.name} label={d.name}>
  <div style={{display:'flex', alignItems: 'center'}}>
    <img
      style={{ maxWidth: 200, maxHeight: 100,  }}
      src={d.url}
    />
    <span className="menu_select_thumb" style={{whiteSpace:"wrap"}}>{d.name}</span>
  </div>
</AutoComplete.Option>);

export const orderStates = [
  {
    name:'orderPrinter',
    display: '拍貼機'
  },
  {
    name:'orderInvitation',
    display: '電子喜帖',
  },
  {
    name:'orderMontage',
    display: '蒙太奇',
  },
  {
    name:'orderPhotobooth',
    display: '韓式三連拍',
  },
  {
    name:'orderWine',
    display: '點點酒',
  },
  ...buyItems,
];

export const liffId = '1654358021-rAWyJ9bj';

export const todo_types_config ={
  '諮詢':{ display:'諮詢紀錄', color:'#fafafa', textColor:'#000' },
  '客服':{ display:'客服類待辦', color:'pink', textColor:'#820014' },
  '美工':{ display:'美工類待辦', color:'#d3adf7', textColor:'#531dab' },
  '工程':{ display:'工程類待辦', color:'#91d5ff', textColor:'#0050b3' },
  '現場':{ display:'現場類待辦', color:'#b7eb8f', textColor:'#135200' },
}
export const todo_types = Object.keys(todo_types_config);

export const activity_status_config = {
  '追蹤': { color:'yellow', },
  '體驗': { color:'pink', },
  '正常': { color:'green', },
  '完成': { color:'#8c8c8c', },
  '取消': { color:'#CCCCCC', },
  '延期': { color:'#999999', },
}
export const activity_status = Object.keys(activity_status_config);

export const activity_types_config ={
  '婚宴':{ display:'婚宴', color:'pink', },
  '活動':{ display:'活動', color:'yellow', },
  '展示':{ display:'展示', color:'green', },
  '長租':{ display:'長租', color:'blue', },
}
export const activity_types = Object.keys(activity_types_config);

export const customerTypeOptions = activity_types.map(e=><Option key={e} value={e}>{e}</Option>);
export const customerStatusOptions = activity_status.map(e=><Option key={e} value={e}>{e}</Option>);

export const preventClick = {
  onClick: (e, o)=>{
    e && e.stopPropagation && e.stopPropagation(); //e.nativeEvent.stopImmediatePropagation(); e.preventDefault();
    o && o.stopPropagation && o.stopPropagation(); //e.nativeEvent.stopImmediatePropagation(); e.preventDefault();
  }
}

export const UrlExists = url=> {
  var http = new XMLHttpRequest();
  http.open('HEAD', url, false);
  http.send();
  return http.status!=404 ? moment(http.getResponseHeader('last-modified')): false;
}

export {moment};

export const contactRateDesc = ['放棄', '未處理', '邀約體驗', '已約體驗', '已體驗，需PUSH', '已體驗，意願高', '已填單，尚未付款']


export const location_type = {
  "大台北": ["台北", "基隆", "新北"],
  "桃竹苗": ["桃園", "新竹", "苗栗"],
  "台中區": ["台中"],
  "彰投": ["彰化", "南投"],
  "南部": ["台南", "高雄", "雲林", "屏東", "嘉義"],
  "東部": ["宜蘭", "花蓮", "臺東", "台東"],
  "其他區": ["澎湖", "金門", "連江", "其他"],
};


export const timeRange = {
  'no_date': [null, null], // 不限
  'after_today':  [moment().startOf('day'), null], // '今日過後'],
  'before_today':  [null, moment().startOf('day')], // '今日以前'],
  'future_month': [moment().startOf('day'), moment().add(1, 'M').endOf('day')], // '未來一個月
  'future_week': [moment().startOf('day'), moment().add(7, 'd').endOf('day')], // '未來一個月
  'pass_week': [moment().subtract(7, 'd').startOf('day'), moment().endOf('day') ], // '上一周
  'pass_month': [moment().subtract(1, 'M').startOf('day'), moment().endOf('day') ], // '過去一個月
  'this_month': [moment().startOf('month'), moment().endOf('month') ], // '下個月
  'next_month': [moment().add(1, 'M').startOf('month'), moment().add(1, 'M').endOf('month') ], // '下個月
  'last_month': [moment().subtract(1, 'M').startOf('month'), moment().subtract(1, 'M').endOf('month') ], // '上個月
  'today': [moment().startOf('day'), moment().endOf('day')], // '今日
  // 'all': [moment({y:2000, M:1, d:1}), moment({y:2050, M:1, d:1})], // '所有']
}

export const timeRangeMapping = [
  ['no_date', '不限'],
  ['today', '今日'],

  ['future_month', '後一月'],
  ['pass_month', '前一月'],

  ['future_week', '後一周'],
  ['pass_week', '前一周'],

  ['this_month', '本月'],
  ['next_month', '下月'],
  ['last_month', '上月'],

  ['after_today', '今日後'],
  ['before_today', '今日前'],

  // ['all', '所有'],
  ['custom', '自訂'],
];

export const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const format_with_mod2 = (number) => {
  var n = number;
  var r = "";
  do {
      var mod = n % 1000;
      n = n / 1000;
      r = ~~mod + (!!r ? "," + r : "")
  } while (n > 1)

  var strNumber = number + "";
  var index = strNumber.indexOf(".");
  if (index > 0) {
      r += strNumber.substring(index);
  }
  return r;
}

export const ensureKeyAndPush = (d, k=default_k, def=[], v, default_k='official') => {
  if(k=='undefined' || k===undefined ){ k = default_k; }
  if (!d.hasOwnProperty(k)){ d[k]=def; }
  if(v){
    d[k].push(v);
  }
}

export const toWeekDay = d=> [0, '一', '二', '三', '四', '五', '六', '日'][d.isoWeekday()];

export const readPaperform = async (id_default="") => {
  const id = prompt("請貼上paperform id", id_default);
  if(!id || id=="") return {};

  message.loading('查詢資料中...', 0)
  const {data:{msg, data, success}} = await axios.get(`${backendUrl()}/api/read_order_data_by_paperform_id/?id=${id}`, {withCredentials: true});
  console.log('data', {msg, data, success});
  message.destroy();
  if(!success){
    message.error('查詢資料發生錯誤:'+msg, 5);
  }
  const planName = planItems.reduce((acc, cur)=>{
    if(acc!=='') return acc;
    if(cur.keywords.some(k=>data["方案"].indexOf(k)>=0)){
      return cur.name;
    }
    return '';
  }, '');

  const extraConfig = (planItems.find(p=>p.name==planName)||{}).config;

  const formData = {
    cfgWebUpload: false,
    groom_name: data["新郎姓名"],
    bride_name: data["新娘姓名"],
    plan: planName,
    maxGames: 2,
    date: moment(data["日期"] + " 13:00:00") || null,
    time_segment: (data["時段"]||"").split(" ")[0],
    orderDate: moment(data["下單時間"]) || null,
    note_machine:"",
    cfgMatt: true,
    orderMontage: false,
    gameEnabled: true,
    total_fee: data["確認總金額"],
    tail_fee: parseInt(data["確認總金額"]||'3000')-3000,
    tail_fee_note: data["尾款明細"],
    order_discount_note: "",

    location_area: data["地區"].split("+")[0],
    location_readable: data["地點"],
    "title": data["官方帳號名稱"],
    "serviceBelong.source.memo": data["團購"],
    "serviceBelong.saling.memo": data["銷售業務"],
  };
  return {formData, extraConfig};
}

export const deliverNameExtra = (s='') => s.split(/[- 0]/)[0];

export const recommend_workers_gen = f => [
  [f.orderPrinter, "拍貼機", 2],
  [f.orderPhotobooth, "韓式", 2],
  [f.orderWine, "點點酒", 2],
];

// export const ensureKeyAndSet = (d, k=default_k, def=[], v, default_k='official') => {
//   if(k=='undefined' || k===undefined ){ k = default_k; }
//   if (!d.hasOwnProperty(k)){ d[k]=def; }
//   if(v){
//     d[k] [v);
//   }
// }